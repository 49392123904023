import '../App.css'

function NotFound() {
  return (
    <main className='colspan12'>
      <h1>Reza Meems kan veel</h1>
      <h2>Maar de pagina die je zoekt, kan hij niet vinden</h2>
    </main>
  )
}

export default NotFound
